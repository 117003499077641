import React from "react";

import {Layout} from "../components/index";

const NotFound = () => {
  return (
    <Layout>
      <h1>Page not found</h1>
    </Layout>
  )
}

export default NotFound
